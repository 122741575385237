// ChartJS plugin to sort each column in stacked COLUMN CHART
// TODO: make it work for horizontal bar chart!
// https://stackoverflow.com/questions/51323890/chart-js-stacked-bar-chart-sorting-values-in-bar-by-value
const SortedBarStack = {
  id: "SortedBarStack",
  beforeDraw: function (chart, args, options) {
    options.sortedData = Array.from(
      { length: chart.data.datasets[0].data.length },
      () => ({ data: [] })
    );

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      dataset.data.forEach((data, index) => {
        options.sortedData[index].data[datasetIndex] = {
          datasetIndex,
          hidden: chart.getDatasetMeta(datasetIndex).hidden || false,
          value: data,
          x: chart.getDatasetMeta(datasetIndex).data[index].x,
          base: chart.getDatasetMeta(datasetIndex).data[index].base,
        };
      });
    });

    const chartLeft = chart.scales.x.left;
    const max = chart.scales.x.max;
    const w = chart.scales.x.width / max;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      dataset.data.forEach((data, index) => {
        options.sortedData[index].data.sort((a, b) => b.value - a.value);

        options.sortedData[index].data.forEach((d, i) => {
          d.base = chartLeft;

          for (let j = 0; j < i; j++) {
            d.base += options.sortedData[index].data[j].hidden
              ? 0
              : w * options.sortedData[index].data[j].value;
          }

          d.x = d.base + w * d.value;
        });
      });
    });
  },
  beforeDatasetDraw: function (chart, args, options) {
    chart.getDatasetMeta(args.index).data.forEach((data, index) => {
      const el = options.sortedData[index].data.find(
        (e) => e.datasetIndex === args.index
      );
      data.x = el.x;
      data.base = el.base;
    });
  },
};

/*
  * Custom Tooltip Plugin
  * - Display currency symbol
  * - Filter out data points with value 0

  options: { interaction: { mode: 'index' } }
  is required to make the tooltip display all the data points
  for the current axis index
*/
const CustomTooltip = {
  id: "CustomTooltip",
  beforeInit: function (chart, args, options) {
    chart.options.plugins.tooltip.displayColors = true;
    chart.options.plugins.tooltip.filter = function (tooltipItem) {
      // Filter out data points with value 0
      return tooltipItem.raw !== 0 && tooltipItem.raw != null;
    };
  },
  // set options.currency to customize the currency symbol
  beforeRender: (chart) => {
    const currency = chart.options.plugins.CustomTooltip.currency || "";

    chart.options.plugins.tooltip.callbacks.label = function (context) {
      const label = context.dataset.label || context.label;
      // using .raw because parsed could be an object { x: 0, y: 0 }
      return `${label}: ${numberToCurrency(context.raw, currency)}`;
    };
  },
};

const SortedTooltip = {
  id: "SortedTooltip",
  beforeInit: function (chart) {
    if (!chart.options.plugins.tooltip) {
      chart.options.plugins.tooltip = {};
    }
    chart.options.plugins.tooltip.itemSort = function (a, b) {
      return b.raw - a.raw;
    };
  },
};

const PaddingBelowLegends = {
  id: "PaddingBelowLegends",
  beforeInit: function (chart) {
    if (!chart.legend) return;

    // https://stackoverflow.com/questions/42585861/chart-js-increase-spacing-between-legend-and-chart/67723827#67723827
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 30;
    };
  },
};

const SmallLegendLabelBoxSize = {
  id: 'SmallLegendLabelBoxSize',
  beforeInit: (chart) => {
    if (chart.options.plugins.legend.labels) {
        chart.options.plugins.legend.labels.boxWidth = 16;
        chart.options.plugins.legend.labels.boxHeight = 12;
    }
  }
};

const DarkModeLabelPlugin = {
  id: "DarkModeLabel",
  beforeInit(chart) {
    const isDarkMode =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    const labelColor = isDarkMode ? "#cbd5e1" : "#1e293b";
    const gridColor = isDarkMode ? "#64748b" : "#cbd5e1";

    if (
      chart.options.scales?.x &&
      chart.options.plugins.DarkModeLabel?.axis != false
    ) {
      chart.options.scales.x.grid.color = gridColor;
      chart.options.scales.y.grid.color = gridColor;
      chart.options.scales.x.ticks.color = labelColor;
      chart.options.scales.y.ticks.color = labelColor;
    }
    if (
      chart.options.plugins?.legend?.labels &&
      chart.options.plugins.DarkModeLabel?.axis != false
    ) {
      chart.options.plugins.legend.labels.color = labelColor;
    }
    if (
      chart.options.plugins?.datalabels &&
      chart.options.plugins.DarkModeLabel?.points != false
    ) {
      chart.options.plugins.datalabels.color = labelColor;
    }
  },
  beforeDraw: function (chart, args, options) {
    const isDarkMode =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    const labelColor = isDarkMode ? "#cbd5e1" : "#000";

    if (chart.options.plugins.legend.labels) {
      chart.options.plugins.legend.labels.color = labelColor;
    }
    if (chart.options.plugins.datalabels) {
      chart.options.plugins.datalabels.color = labelColor;
    }
  },
};

const BeautifyPlugin = {
  id: "beautify",
  beforeInit: function (chart, args, options) {
    chart.data.datasets.forEach(function (dataset) {
      dataset.tension = 0.4;
      // dataset.pointBorderColor = '#fff'
      dataset.pointBorderWidth = 0;
      dataset.pointRadius = 0;
      dataset.pointHoverRadius = 6;
      dataset.borderCapStyle = "round";
      dataset.borderJoinStyle = "round";
    });

    chart.options.animation = {
      duration: 1000,
      easing: "easeInOutQuart",
    };
  },
};

export {
  SortedBarStack,
  CustomTooltip,
  SortedTooltip,
  PaddingBelowLegends,
  SmallLegendLabelBoxSize,
  DarkModeLabelPlugin,
  BeautifyPlugin,
};
