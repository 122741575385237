import "@hotwired/turbo-rails";
import "flowbite/dist/flowbite.turbo.js";

import "./pwa_install";
import "./custom_chart";
import "./controllers";
import "./form_persistence";

import FormPersistence from "./form_persistence";
window.FormPersistence = FormPersistence;

import { RRule } from "rrule";
window.RRule = RRule;

import ApexCharts from "apexcharts";
window.ApexCharts = ApexCharts;

import "chartkick/chart.js";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import {
  SortedBarStack,
  CustomTooltip,
  SortedTooltip,
  PaddingBelowLegends,
  SmallLegendLabelBoxSize,
  DarkModeLabelPlugin,
  BeautifyPlugin,
} from "./custom_chart";

Chart.register(
  BeautifyPlugin,
  SortedBarStack,
  CustomTooltip,
  SortedTooltip,
  PaddingBelowLegends,
  SmallLegendLabelBoxSize,
  DarkModeLabelPlugin,
  SankeyController,
  Flow,
  ChartDataLabels
);

window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels;
window.DarkModeLabelPlugin = DarkModeLabelPlugin;
window.SmallLegendLabelBoxSize = SmallLegendLabelBoxSize;

// hide labels when value is 0
Chart.defaults.set("plugins.datalabels", {
  display: function (context) {
    return context.dataset.data[context.dataIndex] !== 0;
  },
});

import AOS from "aos";
window.AOS = AOS;
AOS.init();

import RecordRTC from "recordrtc";
window.RecordRTC = RecordRTC;

import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import anchor from '@alpinejs/anchor'

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.plugin(anchor)

var alpineInitialized = false;
document.addEventListener("turbo:load", function () {
  if (!alpineInitialized) {
    alpineInitialized = true;
    Alpine.start();
  }
});

document.addEventListener("DOMContentLoaded", function () {
  if (!alpineInitialized) {
    alpineInitialized = true;
    Alpine.start();
  }
});

// to close dialog when visit another page
// so it does not open when back to the page
document.addEventListener("turbo:visit", () => {
  const elements = document.querySelectorAll('[data-dialog-open-value="true"]');
  elements.forEach((element) => {
    element.dataset.dialogOpenValue = "false";
  });
});
