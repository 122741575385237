class FormPersistence {
  constructor(options = {}) {
    this.formId = options.formId;
    this.storage = options.useLocalStorage ? localStorage : sessionStorage;
    this.expiryMinutes = options.expiryMinutes || 30;
    this.autoSaveInterval = null;
    this.excludeFields = options.excludeFields || [];
    this.storageKey = `form_${this.formId}_data`;
    this.expiryKey = `form_${this.formId}_expiry`;
    this.isSubmitting = false;

    // Add Turbo form submission listener if Turbo exists
    if (typeof Turbo !== 'undefined') {
      document.addEventListener('turbo:submit-start', (event) => {
        if (event.target.id === this.formId) {
          this.isSubmitting = true;
          this.clearStorage();
        }
      });

      // Reset submitting state if form submission fails or completes
      document.addEventListener('turbo:submit-end', (event) => {
        if (event.target.id === this.formId) {
          this.isSubmitting = false;
        }
      });
    }
  }

  setup() {
    if (this.autoSaveInterval) {
      this.startAutoSave(autoSaveInterval);
    }

    // Restore form data when page loads
    document.addEventListener('DOMContentLoaded', () => {
      this.restoreForm();
    });

    document.addEventListener('turbo:load', () => {
      this.restoreForm();
    });

    // Clear stored data when form is submitted
    document.getElementById(this.formId).addEventListener('submit', () => {
      this.clearStorage();
    });
  }

  // Save form data
  saveForm() {
    // Don't save if form is currently being submitted
    if (this.isSubmitting) return;

    const form = document.getElementById(this.formId);
    if (!form) return;

    const formData = {};
    const formElements = form.elements;

    for (let element of formElements) {
      // Skip if field should be excluded
      if (this.excludeFields.includes(element.name)) continue;

      // Handle different input types
      switch (element.type) {
        case 'checkbox':
          formData[element.name] = element.checked;
          break;
        case 'radio':
          if (element.checked) {
            formData[element.name] = element.value;
          }
          break;
        case 'select-multiple':
          formData[element.name] = Array.from(element.options)
            .filter(option => option.selected)
            .map(option => option.value);
          break;
        default:
          if (element.name) {
            formData[element.name] = element.value;
          }
      }
    }

    // Set expiry timestamp
    const expiryTime = new Date();
    expiryTime.setMinutes(expiryTime.getMinutes() + this.expiryMinutes);

    // Save to storage
    this.storage.setItem(this.storageKey, JSON.stringify(formData));
    this.storage.setItem(this.expiryKey, expiryTime.getTime().toString());
  }

  // Restore form data
  restoreForm() {
    // Don't restore if form is being submitted
    if (this.isSubmitting) return;

    const form = document.getElementById(this.formId);
    if (!form) return;

    // Check if data exists and hasn't expired
    const expiryTime = parseInt(this.storage.getItem(this.expiryKey));
    if (!expiryTime || new Date().getTime() > expiryTime) {
      this.clearStorage();
      return;
    }

    const savedData = JSON.parse(this.storage.getItem(this.storageKey) || '{}');

    // Restore values to form elements
    Object.entries(savedData).forEach(([name, value]) => {
      const elements = form.elements[name];
      if (!elements) return;

      if (elements instanceof RadioNodeList) {
        // Handle radio buttons and multiple elements with same name
        Array.from(elements).forEach(element => {
          if (element.type === 'radio') {
            element.checked = element.value === value;
          } else if (element.type === 'checkbox') {
            element.checked = value;
          }
        });
      } else {
        // Handle other input types
        if (elements.type === 'checkbox') {
          elements.checked = value;
        } else if (elements.type === 'select-multiple' && Array.isArray(value)) {
          Array.from(elements.options).forEach(option => {
            option.selected = value.includes(option.value);
          });
        } else {
          elements.value = value;
        }
      }
    });
  }

  // Clear stored form data
  clearStorage() {
    this.storage.removeItem(this.storageKey);
    this.storage.removeItem(this.expiryKey);
  }

  // Start auto-saving
  startAutoSave(intervalSeconds = 5) {
    this.autoSaveInterval = setInterval(() => {
      this.saveForm();
    }, intervalSeconds * 1000);
  }

  // Stop auto-saving
  stopAutoSave() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
  }
}

export default FormPersistence;
